









export default {
  name: 'summary-key-value',
  props: {
    name: String,
    value: String,
    equal: Boolean,
    valueSecret: Boolean,
  },
};
