







import { VariableInput } from '@/model/store';
import { PropType } from '@vue/composition-api';
import SummaryKeyValue from '@/components/wizard/steps/summary/parts/KeyValue.vue';

export default {
  name: 'summary-variables',
  components: { SummaryKeyValue },
  props: {
    variables: Array as PropType<VariableInput[]>,
  },
};
