
























import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNewProjectsStore } from '@/stores/new-project';
import SummaryHeadline from '@/components/wizard/steps/summary/parts/Headline.vue';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';
import SummaryList from '@/components/wizard/steps/summary/parts/List.vue';

export default defineComponent({
  name: 'SummaryRecurring',
  components: { SummaryList, AppExpansionPanel, SummaryHeadline },

  setup() {
    const newProjectsStore = useNewProjectsStore();
    const selected = ref<boolean>(false);
    const excludes = computed<string[]>(() => newProjectsStore.deployStep.recurringConfig?.excludes || []);
    const preDeployCommands = computed<string[]>(
      () => newProjectsStore.deployStep.recurringConfig?.remoteCommands.preDeployment || []
    );
    const postDeployCommands = computed<string[]>(
      () => newProjectsStore.deployStep.recurringConfig?.remoteCommands.postDeployment || []
    );

    const click = () => {
      selected.value = !selected.value;
    };

    return {
      selected,
      excludes,
      preDeployCommands,
      postDeployCommands,
      click,
    };
  },
});
