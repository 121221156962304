












import { defineComponent } from '@vue/composition-api';
import SummaryTitle from '@/components/wizard/steps/summary/parts/Title.vue';

export default defineComponent({
  name: 'SummaryGroup',
  components: { SummaryTitle },
  props: {
    title: String,
    goTo: Function,
  },
  setup() {},
});
