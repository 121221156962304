





























import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNewProjectsStore } from '@/stores/new-project';
import SummaryGroup from '@/components/wizard/steps/summary/parts/Group.vue';
import SummaryHeadline from '@/components/wizard/steps/summary/parts/Headline.vue';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';

import { SetupWizardService } from '@/services/setup-wizard-service';
import { SETUP_REPOSITORY } from '@/utils/const';
import { useGitHubStore } from '@/stores/github';
import { githubSourceRepositoryToGithubUrl } from '@/model/storeApiConverter';
export default defineComponent({
  name: 'SummaryRepository',
  components: { SummaryGroup, AppExpansionPanel, SummaryHeadline },

  setup() {
    const newProjectsStore = useNewProjectsStore();
    const githubStore = useGitHubStore();

    const projectName = computed<string>(() => newProjectsStore.projectName);
    const externalRepo = computed<boolean>(() => !!newProjectsStore.sourceRepository);
    const destinationRepoPrivate = computed<boolean | undefined>(
      () => newProjectsStore.destinationRepository.privateRepo
    );
    const destinationRepoBranch = computed<string | undefined>(
      () => newProjectsStore.destinationRepository.repository.branch
    );
    const sourceRepo = computed<string | undefined>(() => {
      return newProjectsStore.sourceRepository
        ? githubSourceRepositoryToGithubUrl(newProjectsStore.sourceRepository)
        : undefined;
    });
    const targetUrl = (): string => {
      return `https://github.com/${githubStore.getOwner}/${newProjectsStore.destinationRepository.repository.repo}`;
    };

    const selected = ref(true);

    const click = () => {
      selected.value = !selected.value;
    };

    const panel = ref(0);

    const goToRepository = () => {
      SetupWizardService.navigate(SETUP_REPOSITORY);
    };

    return {
      panel,
      destinationRepoPrivate,
      destinationRepoBranch,
      targetUrl,
      selected,
      click,
      projectName,
      externalRepo,
      sourceRepo,
      goToRepository,
    };
  },
});
