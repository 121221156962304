



export default {
  name: 'summary-headline',
  props: {
    headline: String,
  },
};
