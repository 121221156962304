







































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNewProjectsStore } from '@/stores/new-project';
import SummaryGroup from '@/components/wizard/steps/summary/parts/Group.vue';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';
import { BuildStep } from '@/model/store';
import SummaryKeyValue from '@/components/wizard/steps/summary/parts/KeyValue.vue';
import SummaryHeadline from '@/components/wizard/steps/summary/parts/Headline.vue';
import SummaryList from '@/components/wizard/steps/summary/parts/List.vue';
import SummaryVariables from '@/components/wizard/steps/summary/parts/Variables.vue';
import { SetupWizardService } from '@/services/setup-wizard-service';
import { SETUP_BUILD } from '@/utils/const';

export default defineComponent({
  name: 'SummaryBuild',
  components: { SummaryHeadline, SummaryKeyValue, SummaryGroup, SummaryVariables, SummaryList, AppExpansionPanel },
  setup() {
    const newProjectStore = useNewProjectsStore();
    const buildSteps = computed<BuildStep[]>(() => newProjectStore.buildSteps);
    const deploymentFolder = computed(() => newProjectStore.deployStep.deploymentFolder);

    const selected = ref('');

    const click = (name: string) => {
      if (selected.value === name) {
        selected.value = '';
      } else {
        selected.value = name;
      }
    };

    const goToBuild = () => {
      SetupWizardService.navigate(SETUP_BUILD);
    };

    return {
      selected,
      click,
      buildSteps,
      deploymentFolder,
      goToBuild,
    };
  },
});
