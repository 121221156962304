









import { defineComponent } from '@vue/composition-api';
import SummaryGroup from '@/components/wizard/steps/summary/parts/Group.vue';
import SummaryBootstrap from '@/components/wizard/steps/summary/parts/Bootstrap.vue';
import SummaryRecurring from '@/components/wizard/steps/summary/parts/Recurring.vue';
import { SetupWizardService } from '@/services/setup-wizard-service';
import { SETUP_DEPLOYMENT } from '@/utils/const';

export default defineComponent({
  name: 'SummaryDeployment',
  components: { SummaryRecurring, SummaryGroup, SummaryBootstrap },
  setup() {
    const goToDeployment = () => {
      SetupWizardService.navigate(SETUP_DEPLOYMENT);
    };

    return {
      goToDeployment,
    };
  },
});
