










































import { defineComponent, ref } from '@vue/composition-api';
import { useNewProjectsStore } from '@/stores/new-project';
import SummaryGroup from '@/components/wizard/steps/summary/parts/Group.vue';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';
import SummaryHeadline from '@/components/wizard/steps/summary/parts/Headline.vue';
import SummaryTemplate from '@/components/wizard/steps/summary/parts/Template.vue';
import SummaryVariables from '@/components/wizard/steps/summary/parts/Variables.vue';
import SetupCronjobs from '@/components/wizard/steps/runtime/cronjobs/Index.vue';
import { SetupWizardService } from '@/services/setup-wizard-service';
import { SETUP_RUNTIME } from '@/utils/const';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SummaryRuntime',
  components: { SummaryGroup, AppExpansionPanel, SummaryHeadline, SummaryVariables, SummaryTemplate, SetupCronjobs },
  setup() {
    const { defaultPhpVersion, databaseEnabled, mailAccountEnabled, runtimeStep } = storeToRefs(useNewProjectsStore());

    const selected = ref(false);

    const click = () => {
      selected.value = !selected.value;
    };

    const goToRuntime = () => {
      SetupWizardService.navigate(SETUP_RUNTIME);
    };

    return {
      selected,
      click,
      defaultPhpVersion,
      databaseEnabled,
      mailAccountEnabled,
      runtimeStep,
      goToRuntime,
    };
  },
});
