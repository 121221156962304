




















import { PropType } from '@vue/composition-api';
import { TemplateFile } from '@/model/store';

export default {
  name: 'summary-template',
  props: {
    templates: {
      type: Array as PropType<TemplateFile[]>,
    },
  },
};
