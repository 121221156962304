







import { PropType } from '@vue/composition-api';

export default {
  name: 'summary-list',
  props: {
    list: {
      type: Array as PropType<string[]>,
    },
  },
};
