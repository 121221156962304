












import { computed, defineComponent } from '@vue/composition-api';
import SummaryRepository from '@/components/wizard/steps/summary/Repository.vue';
import SummaryBuild from '@/components/wizard/steps/summary/Build.vue';
import SummaryRuntime from '@/components/wizard/steps/summary/Runtime.vue';
import SummaryDeployment from '@/components/wizard/steps/summary/Deployment.vue';

import { ProjectTypeGroup } from '@/model/store';
import { useSetupWizardStore } from '@/stores/setup-wizard';

export default defineComponent({
  name: 'SetupSummary',
  components: {
    SummaryBuild,
    SummaryRepository,
    SummaryRuntime,
    SummaryDeployment,
  },
  setup() {
    const setupWizardStore = useSetupWizardStore();
    const dynamicProject = computed(() => setupWizardStore.selectedProjectTypeGroup === ProjectTypeGroup.dynamic);
    return {
      dynamicProject,
    };
  },
});
